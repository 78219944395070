@import url('https://fonts.googleapis.com/css?family=Julius+Sans+One&display=swap');

body{
	background:#dadada;
	color: #252525;
	margin:0;
	padding:0;
	min-height: 100%;
	font-size: 10px;
	font-family: 'Julius Sans One', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
html{
    min-height: 100%;
    overflow-x: hidden; 
    overflow-y: auto;
    overflow: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
button,
svg {
  display: inline-block;
  vertical-align: middle;
}
a{
	text-decoration: none;
}
h1,h2,h3,h4,h5,h6,p{
	font-size: 12px;
	font-weight: normal;
	margin: 0;
	padding: 0;
	color: #dadada;
}
ul{
	list-style: none;
	margin: 0;
	padding: 0;
}
::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
::placeholder{
	color: #000;
	opacity: 1;
	font-size: 10px;
	font-family: 'Julius Sans One', sans-serif;
}
:-ms-input-placeholder{
	color: #000;
	font-size: 10px;
	font-family: 'Julius Sans One', sans-serif;
}
::-ms-input-placeholder{
	color: #000;
	font-size: 10px;
	font-family: 'Julius Sans One', sans-serif;
}
.App {
  text-align: center;
}
.container{
    margin-top: 90px;
	background-color: #2b2b2b;
}

/* HEADER MOBILE */

.header_mobile {
	background-color: #000;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
    width: 100%;
    z-index: 1000;
}
.header_mobile_top{
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_mobile_bottom{
	background-color: #0a0a0a;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
#menuButton{
	height: 30px;
	width: 30px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.fa-bars{
	font-size: 20px;
	color: #dadada;
}
.fa-search{
	font-size: 20px;
}
.green{
	font-size: 20px;
	color: #f2185a;
}
.fa-times{
	font-size: 20px;
	color: #dadada;
}
.logo a{
	display: flex;
}
.logo_img{
	height: 45px;
}
.search_btn{
	height: 30px;
	width: 30px;
	padding: 10px;
	display: flex;
    justify-content: center;
	align-items: center;
	z-index: 200;
}
.heel{
	height: 20px;
	padding: 10px;
}
.logo_circle{
	height: 30px;
}
.header_title{
	font-size: 10px;
	color: #dadada;
	text-transform: capitalize;
}
.sort_btn{
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.fa-sliders-h{
	font-size: 20px;
    color: #dadada;
}
.search_wrap{
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	z-index: 100;
}
#search{
	display: flex;
}
#search svg{
	font-size: 14px !important;
	color: #f9195e;
}
.search_input{
	flex: 1;
	padding: 10px;
	border: none;
	outline-color: rgba(250, 25, 94, 0.5);
	background-color: #dadada;
	color: #000;
	font-size: 10px;
	font-family: 'Julius Sans One', sans-serif;
}
#search_button{
	height: 40px;
	width: 50px;
	background-color: #131313;
    border: none;
    outline: none;
}
#search_suggestions{
	display: none;
	background-color: #dadada;
	text-align: left;
	box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.3);
	z-index: 999999;
}
#search_suggestions li a{
	padding: 14.5px 10px;
    color: #050505;
    font-size: 10px;
    font-family: 'Julius Sans One', sans-serif;
    display: block;
}

/* MENU */

.menu_wrapper{
	display: flex;
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
}
#menu{
	background-color: #0c0c0c;
	width: 250px;
	text-align: left;
	height: 100%;
}
.menu_close{
	height: 30px;
    width: 30px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu{
	height: calc(100% - 50px);
    display: flex;
	flex-direction: column;
	overflow-y: scroll;
}
.menu_user{
	background-color: #151515;
}
.menu_links{
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: #1d1d1d;
}
.menu_links a{
	padding: 12px 10px;
	color: #dadada;
	background-color: #1d1d1d;
    display: flex;
    align-items: center;
}
.menu_footer{
	padding: 10px;
	background-color: #151515;
}
.menu_footer p{
	font-size: 9px;
}
.overlay{
	flex: 1;
	background-color: rgba(0, 0, 0, 0.7);
}
.menu_title{
	padding: 12px 10px;
	color: #dadada;
	display: flex;
	align-items: center;
	background-color: #1d1d1d;
}
.fa-circle{
	font-size: 7px;
	margin-right: 5px;
	color: #f2185a;
}
.menu_id{
	padding: 10px;
	display: flex;
	align-items: center;
}
.profile_id{
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
}
.fa-user{
    color: #b5b5b5;
	font-size: 16px;
}
.menu_id span{
	color: #dadada;
	font-size: 14px;
	height: 20px;
}
.tokens{
    color: #fff;
    display: flex;
    align-items: center;
	justify-content: center;
	font-size: 12px;
	background: #fa195e;
    background: linear-gradient(0deg, #d0174f 0%, #fa195e 100%);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	padding: 10px 16px;
	border-radius: 2px;
}
.id{
	font-size: 14px;
    color: #dadada;
	margin-left: 5px;
}
.balance{
	color: #f2185a;
	margin-top: 5px;
	font-weight: bold;
}
.active{
	background-color: #232323 !important;
    color: #dadada !important;
    border-left: 3px solid #f2185a;
}
.menu_links svg{
	padding-left: 5px;
	font-size: 10px;
}
.space_fixer{
	width: 50px;
	height: 50px;
}


/* MODELS */

.users{
	width:calc(100% - 5px);
	min-height: 100vh;
}
.users:after{
	clear:both;
	content:" ";
	display:block;
}
.users>div{
	width:calc(50% - 5px);
	margin:5px 0 0 5px;
	float:left;
	position:relative;
	overflow: hidden;
}
.users>div>div{
	width:100%;
	padding-bottom:75%;
}
.users>div>div>div{
	position:relative;
}
.users>div img{
	width:100%;
	display:block;
	background-color:#2b2b2b;
}
.users>div img:hover{
	transform: scale(1.1);
}
.users>div a{
	top:0;
	left:0;
	right:0;
}
.users>div .username,.users>div a{
	text-decoration:none;
	position:absolute;
	bottom:0;
}
.users>div .username{
	color:#dadada;
	padding: 2px 5px;
	height: 10px;
	font-size:10px;
	width: calc(100% - 10px);
    background: #050505;
    background: linear-gradient(to right, rgba(5, 5, 5, 0.8) 0%, rgba(5, 5, 5, 0.3) 65%, transparent 100%);
	overflow: hidden;
	text-align: left;
}

/* FILTERS */

.filters_container{
	position: absolute;
    top: 0;
	bottom: 0;
	width: 100%;
	background-color: #0a0a0a;
	height: 40px;
	overflow-x: hidden;
	overflow-y: hidden;
}
.filters{
    white-space: nowrap;
    width: 100%;
    overflow-x: scroll;
    padding: 0 5px;
    box-sizing: border-box;
}
.filter{
	font-size: 10px;
    color: #dadada;
    background-color: #131313;
    border: 1px solid #1d1d1d;
    outline: none;
    height: 30px;
    padding: 0 10px;
    margin: 5px;
	border-radius: 5px;
	font-family: 'Julius Sans One', sans-serif;
}
.filter:hover{
	border: 1px solid #353535;
}
.fa-tag{
	font-size: 8px;
	color: #fa195e;
}

/* FOOTER */

.footer{
	background-color: #000;
	margin-top: 5px;
	color: #dadada;
}
.footer_content{
	padding: 20px 10px;
	text-align: justify;
	background-color: #0a0a0a;
}
.footer_content h1{
    font-size: 12px;
    color: #f2185a;
	margin-bottom: 10px;
	text-transform: capitalize;
}
.footer_sec{
	padding: 10px;
	font-size: 9px;
	display: flex;
	justify-content: center;
}
.footer_contact a{
	color: #dadada;
	display: flex;
	transition: .1s ease;
}
.footer_contact a:hover{
	color: #f2185a;
}
.footer_friends{
	margin:20px;
	padding:20px;
	background:#151515;
	color:#fff;
	font-size:12px;
	line-height:20px;
}

.fa-envelope{
	margin-right: 3px;
}
.footer_friends a {
	color: #f2185a;
}
/* CONTACT */

.contact{
	min-height: 100vh;
	padding: 10px;
	text-align: center;
}
.contact h1{
	padding: 10px;
	color: #fa195e;
	font-size: 16px;
}
.contact p{
	padding: 10px;
	color: #dadada;
}

/* 404 */

.not_found{
	min-height: 100vh;
}
.not_found h1{
	padding: 20px;
	color: #dadada;
	font-size: 16px;
	text-align: center;
}

/* CHATROOM */

.for_chatroom{
	margin-top: 90px;
}
iframe{
	border: none;
	padding: 0;
	margin: 0;
	background-color: #1b1b1b;
}
.chatroom{
	background-color: #1b1b1b;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    font-size: 14px;
	height: 75vw;
	color: #dadada;
}
.chatroom-contentwrap{
    padding: calc(75vw - 90px) 0 5px 0;
}
.chatroom-go-back{
	position: absolute;
	top: 0;
	left: 0;
    font-size: 20px;
    color: #dadada;
    padding: 10px 15px;
    display: flex;
	background-color: rgba(16, 16, 16, 0.5);
	cursor: pointer;
	transition: .1s ease;
}
.chatroom-go-back:hover{
	color: #fa195e;
}
.chatroom-go-next{
	position: absolute;
	top: 0;
	right: 0;
    font-size: 20px;
    color: #dadada;
    padding: 10px 15px;
    display: flex;
	background-color: rgba(16, 16, 16, 0.5);
	cursor: pointer;
	transition: .1s ease;
}
.chatroom-go-next:hover{
	color: #fa195e;
}
.chatroom-bar{
    display: flex;
	padding: 5px;
}
.chatroom-bar h1{
	font-size: 10px;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	width: 100%;
    height: 100%;
}
.chatroom-bar-model{
    flex: 1;
    background-color: #101010;
	overflow: hidden;
	height: 25px;
	display: flex;
	align-items: center;
	padding-left: 10px;
}
.chatroom-bar-btns{
	display: flex;
}
.chatroom-bar-btns svg{
	font-size: 11px;
}
.chatroom-bar-btn{
	margin-left: 5px;
	padding: 5px 12px;
	color: #dadada;
	font-size: 10px;
	display: flex;
	align-items: center;
    background-color: #101010;
}
.credits{
    background-color: #f2185a;
	color: #fff;
	border: 1px solid #f2185a;
}
.chatroom-info{
    margin: 5px;
    margin-top: 0;
    padding: 10px;
    background-color: #151515;
}
.chatroom-info div{
	display: flex;
	/*align-items: center;*/
	margin-bottom: 10px;
}
.chatroom-info span{
	padding-right: 10px;
	color: #f2185a;
	font-size: 10px;
}
.chatroom-info p{
	margin: 0;
	text-transform: capitalize;
	text-align: justify;
	font-size: 10px;
}
.chatroom-contentwrap h4{
	padding: 10px;
	padding-top: 15px;
}
.fa-camera{
	color: #dadada;
}
.chatroom-profile-picture{
	width: 130px;
	border: 1px solid rgba(242, 24, 90, 0.3);
}
.highlight{
	color: #f2185a;
}

/* DESKTOP HEADER */

.for_desktop{
	margin-top: 0;
}
.header_desktop{
	background-color: #000;
}
.part_top{
	padding: 5px;
	display: flex;
    justify-content: space-between;
}
.part_logo{
	display: flex;
	align-items: center;
	color: #dadada;
}
.part_logo a{
	display: flex;
}
.part_menu{
	display: flex;
}
.part_menu ul{
	display: flex;
}
.part_menu ul li a{
    padding: 18.5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #dadada;
    transition: .1s ease;
}
.part_menu ul li a:hover{
	color: #ff417b;
}
.active_link{
	color: #ff417b !important;
}
.active_network{
	border: 1px solid rgba(250, 25, 94, 0.8) !important;
	color: #fff !important;
}
.models_online{
	margin-left: 5px;
	color: #dadada;
    display: flex;
    align-items: baseline;
}
.count_span{
	color: #ff588b;
	font-size: 10px;
	margin-right: 3px;
}
.part_bottom{
	background-color: #0a0a0a;
	display: flex;
}
.part_bottom_left{
	display: flex;
}
.part_bottom_left h1{
	color: #fff;
    align-self: center;
    display: flex;
    align-items: center;
    padding: 10px;
    background: #fa195e;
    background: linear-gradient(0deg, #d0174f 0%, #fa195e 100%);
    text-shadow: 1px 1px 2px rgb(0, 0, 0);
}
.networks{
	display: flex;
    align-items: center;
	padding: 0 5px;
}
.networks a{
    color: #dadada;
	background-color: #232323;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    transition: .1s ease;
    text-transform: uppercase;
    padding: 5px 7px;
	margin: 0 5px;
    border: 1px solid rgba(250, 25, 94, 0.5);
}
.networks a:hover{
    border: 1px solid rgba(250, 25, 94, 0.8);
	color: #fff;
}
.part_bottom_right{
	flex: 1;
}
.part_mixed{
	background-color: #151515;
	padding: 5px 10px;
	display: flex;
    align-items: center;
}
.part_title{
	flex: 1;
	text-align: left;
	overflow: hidden;
}
.part_title h1{
    font-size: 12px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
	color: #dadada;
	text-shadow: 1px 1px 2px rgba(5, 5, 5, 0.25);
}
.filter_tag{
	font-size: 9px;
    padding: 5px 7px;
    text-transform: uppercase;
    border: none;
    outline: none;
    cursor: pointer;
	margin: 5px 2px;
	background-color: #232323;
	color: #dadada;
	border: 1px solid #333;
	transition: .1s ease;
	border-radius: 3px;
	font-family: 'Julius Sans One', sans-serif;
}
.filter_tag:hover{
	color: #fff;
	border: 1px solid #4a4a4a;
}



/* media queries */

@media only screen and (min-width: 414px) {
	.users>div .username{
		padding: 5px;
	}
	.chatroom-bar-btn{
		padding: 5px 16px;
	}
	.chatroom-bar-model{
		height: 30px;
	}
}

@media only screen and (min-width: 568px) {
	.users>div {
		width: calc(33.3333% - 5px);
	}
	.chatroom-bar-btn{
		padding: 5px 20px;
	}
}

@media only screen and (min-width: 768px) {
	.users>div {
		width: calc(25% - 5px);
	}
	#menu{
		width: 300px;
	}
	.menu_id{
		padding: 25px 15px;
	}
	.menu_title{
		padding: 15px;
		font-size: 14px;
	}
	.menu_links a{
		padding: 15px;
		font-size: 14px;
	}
	.fa-user{
		font-size: 22px;
	}
	.id{
		font-size: 16px;
		text-transform: uppercase;
	}
	.balance{
		font-size: 14px;
	}
	.tokens{
		font-size: 16px;
	}
	.menu_footer p {
		font-size: 12px;
		text-align: center;
	}
	.chatroom-bar-btn {
		padding: 7px 25px;
	}
	.credits{
		padding: 7px 30px !important;
	}
	.chatroom-profile-picture {
		width: 150px;
	}
}

/* Desktop */

@media only screen and (min-width: 769px) {
	.users>div {
		width: calc(25% - 5px);
	}
	.container{
		margin-top: 0;
	}
	.logo_img {
		height: 50px;
	}
	.search_wrap {
		position: relative;
	}
	#search{
		margin: 5px 10px;
		margin-left: 0;
	}
	#search_button{
		height: 34px;
		cursor: pointer;
		transition: .1s ease;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #232323;
	}
	#search_button:hover{
		background-color: #2f2f2f;
	}
	.search_input{
		background-color: #dadada;
		padding: 7.5px;
		border: 1px solid #232323;
		border-right: none;
		color: #000;
		outline-color: rgba(250, 25, 94, 0.8);
	}
	.fa-search {
		font-size: 15px;
	}
	::placeholder{
		color: #969696;
		font-size: 10px;
	}
	:-ms-input-placeholder{
		color: #969696;
		font-size: 10px;
	}
	::-ms-input-placeholder{
		color: #969696;
		font-size: 10px;
	}
	.fa-tag {
		color: #f2185a;
	}
	.footer_content {
		padding: 20px;
		line-height: 1.5;
	}
	.chatroom-contentwrap {
		padding: calc(75vw - 147px) 0 10px 0;
	}
	.chatbox{
		display: none;
	}
	.chatroom-bar-btn {
		padding: 7px 25px;
		transition: .1s ease;
	}
	.chatroom-bar-btn:hover{
		background-color: #131313;
	}
	.chatroom-bar h1{
		font-size: 12px;
	}
	.fa-circle {
		font-size: 8px;
	}
	.chatroom-bar-btns svg {
		font-size: 12px;
	}
	.chatroom-info span{
		font-size: 12px;
	}
	.chatroom-info p {
		font-size: 12px;
	}
	.credits{
		padding: 7px 80px !important;
		transition: .1s ease !important;
		font-size: 12px;
	}
	.credits:hover{
		background-color: #de1854;
	}
	.chatroom-contentwrap h4 {
		font-size: 12px;
		text-align: left;
	}
	#search_suggestions{
		position: absolute;
		top: 38px;
		left: 0;
		background-color: #232323;
	}
	#search_suggestions li{
		height: 34px;
	}
	#search_suggestions li:hover{
		background-color: #0e0e0e;
	}
	#search_suggestions li a{
		color: #dadada;
		padding: 11.5px 10px;
	}
	#search_suggestions li a:hover{
		color: #fff;
	}
	.not_found h1{
		text-align: left;
	}
	.contact{
		text-align: left;
	}
	.footer_contact a{
		font-size: 10px;
	}
}

@media only screen and (min-width: 1024px) {
	.users>div {
		width: calc(20% - 5px);
	}
	.part_menu ul li a{
		padding: 18.5px;
	}
	.part_bottom_right {
		flex: unset
	}
	.part_bottom{
		justify-content: space-between;
	}
	.search_input {
		width: 250px;
	}
	.part_bottom_left h1{
		padding: 10px 30px;
	}
}

@media only screen and (min-width: 1280px) {
	.users>div {
		width: calc(16.6666666667% - 5px);
	}
	.part_menu ul li a {
		padding: 18.5px 20px;
	}
	.networks a{
		padding: 5px 12px;
	}
}

@media only screen and (min-width: 1520px) {
	.users>div {
		width: calc(14.2857142857% - 5px);
	}
}

@media only screen and (min-width: 1760px) {
	.users>div {
		width: calc(12.5% - 5px);
	}
}

@media only screen and (min-width: 1920px) {
	.users>div {
		width: calc(11.1111111111% - 5px);
	}
}

@media only screen and (min-width: 2200px) {
	.users>div {
		width: calc(10% - 5px);
	}
}

@media only screen and (min-width: 2560px) {
	.users>div {
		width: calc(9.09090909091% - 5px);
	}
}

@media only screen and (min-width: 2800px) {
	.users>div {
		width: calc(8.33333333333% - 5px);
	}
}